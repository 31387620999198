import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    clearTemplatesDocuments,
    documentsTemplatesSelector,
    downloadDocumentRequest,
    getDocumentsRequest,
    getTemplatesDocuments
} from "../../../ducks/documents";
import DocumentTC from "./regTransportCompanyTabs/document";
import {SETTINGS_TYPE_SHOW} from "../../../constants/formTypes";

const Docs = ({id, name, settings, updateHistory, docTemplateKey, settingName, onlyOne = true, formats, shouldLoadDocuments = false}) => {
    const dispatch = useDispatch();

    const documents = useSelector(state => documentsTemplatesSelector(
        state,
        docTemplateKey,
        'templateKey'));

    useEffect(() => {
        if (id && name) {
            dispatch(getTemplatesDocuments({id, grid: name}));
            shouldLoadDocuments && getDocs();
        } else {
            dispatch(clearTemplatesDocuments());
        }
    }, [id, name]);

    const getDocs = () => {
        dispatch(getDocumentsRequest({gridName: name, cardId: id}))
    }

    const handleDownload = id => {
        dispatch(downloadDocumentRequest({ id }))
    }

    return (
        <>
            {documents.map(doc => (
                <DocumentTC
                    formats={formats}
                    disabled={settings[settingName] === SETTINGS_TYPE_SHOW}
                    onlyOne={onlyOne}
                    getDocs={getDocs}
                    handleDownload={handleDownload}
                    typeId={doc.typeId}
                    id={id}
                    fileId={doc.fileId}
                    label={doc.name}
                    text={doc.description}
                    img={doc.file !== '' ? doc.file : null}
                    gridName={name}
                    updateHistory={updateHistory}
                />
            ))}
        </>
    );
};

export default Docs;