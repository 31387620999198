import React, { useCallback } from 'react';
import {Form, Grid} from 'semantic-ui-react';
import FormField from '../formField';
import {SHIPPINGS_GRID} from "../../../../constants/grids";
import {useSelector} from "react-redux";
import {columnsTypesConfigSelector} from "../../../../ducks/representations";
import {auctionTypeIsAnyPrice} from "./auctionUtils";

const AuctionParameters = ({ form = {}, onChange, settings, error }) => {
    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID));

    const handleChangeAuctionStartDateTime = useCallback((e, { name, value }) => {
        onChange(e, {
            name,
            value,
        });
        onChange(e, {
            name: 'auctionFinishDateTime',
            value: value ? form.auctionFinishDateTime : '',
        });
    }, []);

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={4} stretched>
                    <Grid.Column>
                        <FormField
                                name="auctionType"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                    </Grid.Column>

                    {!auctionTypeIsAnyPrice(form) && <>
                        <Grid.Column>
                            <FormField
                                name="auctionStartingPrice"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <FormField
                                name="auctionStep"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    </>}

                    <Grid.Column>
                        <FormField
                                name="auctionCurrencyId"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={4} stretched>
                    <Grid.Column>
                        <FormField
                                name="auctionStartDateTime"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={handleChangeAuctionStartDateTime}
                            />
                    </Grid.Column>

                    <Grid.Column>
                        <FormField
                                name="auctionFinishDateTime"
                                isDisabled={!form['auctionStartDateTime']}
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                    </Grid.Column>

                    <Grid.Column>
                        <FormField
                                name="auctionDecisionType"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                    </Grid.Column>

                    <Grid.Column>
                        <FormField
                                name="auctionProlongation"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {!!(form.tcToAuction || []).length && <Grid.Column>
                        <FormField
                                text={form.isExcept ?  "tcNotAllowedToAuction": "tcAllowedToAuction"}
                                name="tcToAuction"
                                columnsConfig={columnsConfig}
                                form={form}
                                settings={settings}
                                error={error}
                                onChange={onChange}
                            />
                    </Grid.Column>}
                </Grid.Row>
            </Grid> 
        </Form>
    );
};

export default AuctionParameters;
