import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Grid, Icon, Segment} from 'semantic-ui-react';
import FormField from '../formField';
import {useDispatch, useSelector} from 'react-redux';
import {columnsTypesConfigSelector} from '../../../../ducks/representations';
import {SHIPPINGS_GRID} from '../../../../constants/grids';
import {DICTIONARY_NEW_LINK} from '../../../../router/links';
import useReactRouter from 'use-react-router';
import {SETTINGS_TYPE_HIDE, SETTINGS_TYPE_SHOW} from '../../../../constants/formTypes';
import {ATTRACTED} from "../../../../constants/ownershipTypes";
import {
    checkDriver,
    checkLicenseDriverSelector,
    setCheckDriver,
} from "../../../../ducks/gridCard";
import DriverLicenseModal from "./driverLicenseModal";
import LinkToCardBtn from "../linkToCardBtn";
import {
    getDocumentTypesRequest,
} from "../../../../ducks/documents";
import Docs from "../docs";

const Information = ({form = {}, updateHistory, name, onChange, settings = {}, error}) => {
    const {t} = useTranslation();
    const {history, location} = useReactRouter();
    const dispatch = useDispatch();

    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID)) || {};
    const checkLicenseDriver = useSelector(state => checkLicenseDriverSelector(state));

    const [openDriverLicenseModal, setOpenDriverLicenseModal] = useState(false);

    const transportCompanyVehicleOwnershipType = 'transportCompanyVehicleOwnershipType';
    const trailerId = 'trailerId';
    const vehicleId = 'vehicleId';
    const docsTemplateKeys = [
        {
            name: 'ShippingPrintedForm',
            settingName: 'applicationDisplay'
        },
        {
            name: 'ShippingAttachDocument',
            settingName: 'allowedEditShippingDocumentsAttachFile',
            onlyOne: false
        },
    ];

    const phoneNumberBindingType = {
        bindByDriver: 0,
        bindByVehicle: 1
    }

    const handleChangeOwnershipType = useCallback((e, {name, value}) => {
        onChange(e, {
            name,
            value,
        });

        if (name === vehicleId && !value) {
            onChange(e, {
                name: transportCompanyVehicleOwnershipType,
                value: null,
            });
            onChange(e, {
                name: 'carrierVehicleOwnershipType',
                value: null,
            });
        }
        if (name === vehicleId && value) { //автозаполнение из ТС
            onChange(e, {
                name: transportCompanyVehicleOwnershipType,
                value: value['transportCompanyOwnershipType'],
            });
            onChange(e, {
                name: 'carrierName',
                value: value['carrier'],
            });
            onChange(e, {
                name: 'inn',
                value: value['carrierInn'],
            });
            onChange(e, {
                name: 'legalAddress',
                value: value['carrierLegalAddress'],
            });
            onChange(e, {
                name: 'telephoneNumber',
                value: value['carrierTelephoneNumber'],
            });
            onChange(e, {
                name: 'carrierVehicleOwnershipType',
                value: value['carrierOwnershipType'],
            });
            if (
                value['driverTelephoneNumber']
                && (value['phoneNumberBindingType'] === phoneNumberBindingType.bindByVehicle
            )) { //номер телефона водителя из ТС
                onChange(e, {
                    name: 'driverTelephoneNumber',
                    value: value['driverTelephoneNumber'],
                });
            } else if (form.editableDriverId && form.editableDriverId['driverTelephoneNumber']) {
                onChange(e, {
                    name: 'driverTelephoneNumber',
                    value: form.editableDriverId['driverTelephoneNumber'],
                });
            }
        }
        if (name === trailerId && value) {
            onChange(e, {
                name: 'carrierTrailerOwnershipType',
                value: value['carrierOwnershipType'],
            });
        }
        if (name === trailerId && !value) {
            onChange(e, {
                name: 'carrierTrailerOwnershipType',
                value: null,
            });
        }
    }, [form]);

    const autocompleteItemsVehicle = [
        {
            name: 'inn',
            nameValue: 'carrierInn'
        },
        {
            name: 'carrierName',
            nameValue: 'carrier'
        },
        {
            name: 'legalAddress',
            nameValue: 'carrierLegalAddress'
        },
        {
            name: 'address',
            nameValue: 'carrierAddress'
        },
        {
            name: 'cityName',
            nameValue: 'carrierCityName'
        },
        {
            name: 'postalCode',
            nameValue: 'carrierPostalCode'
        },
        {
            name: 'telephoneNumber',
            nameValue: 'carrierTelephoneNumber'
        },
        {
            name: 'carrierVehicleOwnershipType',
            nameValue: 'carrierOwnershipType'
        },
    ];

    const autocompleteItemsTrailer = [
        {
            name: 'carrierTrailerOwnershipType',
            nameValue: 'carrierOwnershipType'
        }
    ];

    useEffect(() => {
        setOpenDriverLicenseModal(!checkLicenseDriver);
    }, [checkLicenseDriver]);

    const setDriver = (e, {value, name}) => {
        onChange(e, {
            name,
            value,
        });

        if (
            (form[vehicleId] && !form[vehicleId]['driverTelephoneNumber']) //если в текущем выбранном ТС нет номера телефона водителя, то номер берем из данных водителя
            || (value && (value['phoneNumberBindingType'] === phoneNumberBindingType.bindByDriver))
        ) {
            onChange(e, {
                name: 'driverTelephoneNumber',
                value: value ? value.driverTelephoneNumber : '',
            });
        }
        value && dispatch(checkDriver(value.value));
    };

    useEffect(() => {
        //для проверки ВУ при добавлении водителя через соответствующую кнопку
        if (location.state && location.state.lookup && location.state.name === "editableDriverId") {
            dispatch(checkDriver(location.state.lookup.value));
            window.history.replaceState(null, '');
        }

        if (location.state && location.state.lookup && location.state.name === vehicleId) {
            window.history.replaceState(null, '');
        }
    }, [location.state]);

    useEffect(() => {
            if (form.id) dispatch(getDocumentTypesRequest())
        }, [form.id])

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="shippingNumber"
                            isTranslate
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="waybillNumberOneC"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="shipmentNumber"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width="6">
                        <FormField
                            name="transportCompanyId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                     <Grid.Column  width="4">
                        <FormField
                            name="quotationId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width="4">
                        <FormField
                            name="transportCompanyServiceTypeId"
                            isTranslate
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width="2">
                        <FormField
                            name="tarifficationType"
                            text="shippingTarifficationType"
                            isTranslate
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    {/* <Grid.Column>
                        <FormField
                            name="customsClearanceRequired"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="customsPostAddress"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column> */}
                    <Grid.Column>
                        <FormField
                            name="customsPostImportId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="customsPostExportId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    {
                        columnsConfig["clientId"] &&
                         <Grid.Column>
                        <FormField
                            name="clientId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    }
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('vehicleInfoGroup')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="vehicleTypeId"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="numberOfVehicles"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="palletStacking"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="shippingUploadingMethod"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={1}>
                                        <Grid.Column width="16">
                                            <FormField
                                                name="addTempControl"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                                rows="1"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <Grid>
                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={14}>
                                            <FormField
                                                name={vehicleId}
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={handleChangeOwnershipType}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={2} textAlign="right">
                                            <div className='d-flex'>
                                                <LinkToCardBtn
                                                    dictionaryName='vehicles'
                                                    val={form[vehicleId]}
                                                />
                                                <Button
                                                    icon
                                                    disabled={
                                                        settings[vehicleId] === SETTINGS_TYPE_SHOW ||
                                                        settings[vehicleId] === SETTINGS_TYPE_HIDE ||
                                                        columnsConfig[vehicleId].isReadOnly
                                                    }
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: DICTIONARY_NEW_LINK.replace(
                                                                ':name',
                                                                'vehicles',
                                                            ),
                                                            state: {
                                                                pathname: location.pathname,
                                                                form,
                                                                autocompleteItems: autocompleteItemsVehicle,
                                                                name: vehicleId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Icon name="add"></Icon>
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={14}>
                                            <FormField
                                                name={trailerId}
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={handleChangeOwnershipType}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={2} textAlign="right">
                                            <div className='d-flex'>
                                                <LinkToCardBtn
                                                    dictionaryName='trailers'
                                                    val={form[trailerId]}
                                                />
                                                <Button
                                                    icon
                                                    disabled={
                                                        settings[trailerId] === SETTINGS_TYPE_SHOW ||
                                                        settings[trailerId] === SETTINGS_TYPE_HIDE ||
                                                        columnsConfig[trailerId].isReadOnly
                                                    }
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: DICTIONARY_NEW_LINK.replace(
                                                                ':name',
                                                                'trailers',
                                                            ),
                                                            state: {
                                                                pathname: location.pathname,
                                                                form,
                                                                autocompleteItems: form[transportCompanyVehicleOwnershipType] !== ATTRACTED ? autocompleteItemsTrailer : null,
                                                                name: trailerId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Icon name="add"></Icon>
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={9}>
                                            <FormField
                                                name="editableDriverId"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={setDriver}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <FormField
                                                isDisabled={!form['editableDriverId']}
                                                name="driverTelephoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                                warningText={'check_number'}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={2} textAlign="right">
                                            <div className='d-flex'>
                                                <LinkToCardBtn
                                                    dictionaryName='transportCompanyDrivers'
                                                    val={form['editableDriverId']}
                                                />
                                                <Button
                                                    icon
                                                    disabled={
                                                        settings['editableDriverId'] === SETTINGS_TYPE_SHOW ||
                                                        settings['editableDriverId'] === SETTINGS_TYPE_HIDE ||
                                                        columnsConfig['editableDriverId'].isReadOnly
                                                    }
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: DICTIONARY_NEW_LINK.replace(
                                                                ':name',
                                                                'transportCompanyDrivers',
                                                            ),
                                                            state: {
                                                                pathname: location.pathname,
                                                                autocompleteItems: [{
                                                                    name: 'driverTelephoneNumber',
                                                                    nameValue: 'driverTelephoneNumber'
                                                                }],
                                                                form,
                                                                name: 'editableDriverId',
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Icon name="add"></Icon>
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('commonCargoInfo')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column width="6">
                                            <FormField
                                                name="shippingCargoUnits"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="6">
                                            <FormField
                                                name="productTypes"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="weightKg"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={4}>
                                        <Grid.Column>
                                            <FormField
                                                name="cargoDimensionType"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="cargoCost"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="cargoCostCurrencyId"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="isDangerousCargo"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('commonRouteInfo')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingClientName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingClientName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingAddress1"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingAddress1"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                     <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingCity"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingCity"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingPostalCode"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingPostalCode"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingAddress2And3"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingAddress2And3"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingDateTime"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingDateTime"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className='fields-bottom'>
                                        <Grid.Column width="7"/>
                                        <Grid.Column width="3">
                                            <FormField
                                                name="totalDeliveryCost"
                                                columnsConfig={{
                                                    ...columnsConfig,
                                                    totalDeliveryCost: { ...columnsConfig["totalDeliveryCost"], isReadOnly: true }
                                                }}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="3">
                                            <FormField
                                                name="shippingCurrencyId"
                                                columnsConfig={{
                                                    ...columnsConfig,
                                                    shippingCurrencyId: {
                                                        ...columnsConfig["shippingCurrencyId"],
                                                        isReadOnly: true
                                                    }
                                                }}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="3">
                                            <FormField
                                                name="estimatedPaymentDateForShipping"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                name="additionalInfo"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="tripCreatorName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="tripCreatorPhoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="tripCreatorEmail"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <DriverLicenseModal
                open={openDriverLicenseModal}
                error={error}
                onClose={()=> {
                    setOpenDriverLicenseModal(false);
                    dispatch(setCheckDriver({checkLicense: true}));
                }}
                driverId={form['editableDriverId'] && form['editableDriverId'].value}
            />
            <Grid>
                <Grid.Row>
                    {
                        docsTemplateKeys.map(s => (
                            <Docs
                                name={name}
                                updateHistory={updateHistory}
                                id={form.id}
                                settings={settings}
                                docTemplateKey={s.name}
                                settingName={s.settingName}
                                onlyOne={s.onlyOne}
                            />
                        ))
                    }
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Information;
