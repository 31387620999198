import React from 'react';
import Docs from "../docs";
import {Form, Grid} from "semantic-ui-react";
import {QUOTATIONS_FILE_FORMATS} from "../../../../constants/settings";

const GeneralInfoFile = ({id, name, settings, updateHistory}) => {
    return (
        <Form className='general-info-file'>
            <Grid>
                <Grid.Row>
                    <Docs
                        onlyOne={false}
                        formats={QUOTATIONS_FILE_FORMATS}
                        name={name}
                        updateHistory={updateHistory}
                        id={id}
                        settings={settings}
                        docTemplateKey='quotationDocument'
                        settingName='generalInfoDocument'
                        shouldLoadDocuments
                    />
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default GeneralInfoFile;